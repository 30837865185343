import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import AdminView from '../views/AdminView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: 'main',
        name: 'main',
        component: () => import('../views/MainView.vue'), 
        meta: { group: 'main', root: true, mobiletitle: '主頁' }
      },
      {
        path: 'point_log',
        name: 'pointlog',
        component: () => import('../views/Pointlog.vue'), 
        meta: { group: 'main', root: false, mobiletitle: '會員點數記錄' }
      },
      {
        path: 'address_books',
        name: 'address',
        component: () => import('../views/AddressBookView.vue'), 
        meta: { group: 'address', root: true, mobiletitle: '收件/申報人' }
      },
      {
        path: 'address_books/create',
        name: 'address_add',
        component: () => import('../views/AddressBookAddView.vue'), 
        meta: { group: 'address', root: false, mobiletitle: '新增' }
      },
      {
        path: 'address_books/:aid',
        name: 'address_modify',
        component: () => import('../views/AddressBookModifyView.vue'), 
        meta: { group: 'address', root: false, mobiletitle: '修改' }
      },
      {
        path: 'package',
        name: 'package',
        component: () => import('../views/PackageView.vue'), 
        meta: { group: 'package', root: true, mobiletitle: '包裏管理' }
      },
      {
        path: 'forecast',
        name: 'forecast',
        component: () => import('../views/ForecastView.vue'), 
        meta: { group: 'forecast', root: true, mobiletitle: '預報包裏' }
      },
      {
        path: 'to_forecast/:logis_num',
        name: 'to_forecast',
        component: () => import('../views/ForecastStock.vue'), 
        meta: { group: 'package', root: false, mobiletitle: '認領包裏' }
      },
      {
        path: 'order',
        name: 'order',
        component: () => import('../views/OrderView.vue'), 
        meta: { group: 'order', root: true, mobiletitle: '訂單/追縱' }
      },
      {
        path: 'order/create',
        name: 'order_add',
        component: () => import('../views/OrderAddView.vue'), 
        meta: { group: 'package', root: false, mobiletitle: '委託運單' }
      },
      {
        path: 'order/:order_id',
        name: 'order_edit',
        component: () => import('../views/OrderEditView.vue'), 
        meta: { group: 'order', root: false, mobiletitle: '修改運單' }
      },
      {
        path: 'password',
        name: 'chg_password',
        component: () => import('../views/ChgPassword.vue'), 
        meta: { group: 'user', root: true, mobiletitle: '會員資料' }
      },
      {
        path: 'purchasing_agent',
        name: 'purchasing_agent',
        component: () => import('../views/PurchasingAgent.vue'), 
        meta: { group: 'jp_order', root: true, mobiletitle: '代購服務' }
      },
      {
        path: 'jp_order',
        name: 'jp_order',
        component: () => import('../views/JpOrder.vue'), 
        meta: { group: 'jp_order', root: false, mobiletitle: '日本代購' }
      },
      {
        path: 'jp_order/create',
        name: 'jp_create',
        component: () => import('../views/JpCreate.vue'), 
        meta: { group: 'jp_order', root: false, mobiletitle: '委託日本代購' }
      },
      {
        path: 'kr_order',
        name: 'kr_order',
        component: () => import('../views/KrOrder.vue'), 
        meta: { group: 'jp_order', root: false, mobiletitle: '韓國代購' }
      },
      {
        path: 'kr_order/create',
        name: 'kr_create',
        component: () => import('../views/KrCreate.vue'), 
        meta: { group: 'jp_order', root: false, mobiletitle: '委託韓國代購' }
      },
      {
        path: 'cn_order',
        name: 'cn_order',
        component: () => import('../views/CnOrder.vue'), 
        meta: { group: 'jp_order', root: false, mobiletitle: '中國代賟' }
      },
      {
        path: 'cn_order/create',
        name: 'cn_create',
        component: () => import('../views/CnCreate.vue'), 
        meta: { group: 'jp_order', root: false, mobiletitle: '委託中國代購' }
      },
      {
        path: 'apply_service',
        name: 'apply_service',
        component: () => import('../views/ServiceList.vue'),
        meta: { group: 'apply_service', root: true, mobiletitle: '服務申請' }
      },
      {
        path: 'apply_service/create',
        name: 'apply_service_create',
        component: () => import('../views/ApplyService.vue'),
        meta: { group: 'apply_service', root: false, mobiletitle: '服務申請' }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView 
  },
  {
    path: '/nimda',
    name: 'admin',
    component: AdminView 
  },
  {
    path: '/nimda/home',
    name: 'admin_home',
    component: () => import('../views/AdminHome.vue'), 
    children: [
      {
        path: 'upload',
        name: 'upload',
        component: () => import('../views/UploadPackage.vue'), 
        meta: { group: 'main', root: true }
      },
      {
        path: 'au_package',
        name: 'au_package',
        component: () => import('../views/AuPackage.vue'), 
        meta: { group: 'package', root: true }
      },
      {
        path: 'forecast',
        name: 'admin_forecast',
        component: () => import('../views/ForecastAdmin.vue'), 
        meta: { group: 'forecast', root: true }
      },
      {
        path: 'rank_setting',
        name: 'rank_setting',
        component: () => import('../views/RankView.vue'), 
        meta: { group: 'rank_setting', root: true }
      },
      {
        path: 'rank_setting/create',
        name: 'rank_setting_add',
        component: () => import('../views/RankAdd.vue'), 
        meta: { group: 'rank_setting', root: false }
      },
      {
        path: 'rank_setting/:rank_setting_id',
        name: 'rank_setting_modify',
        component: () => import('../views/RankModify.vue'), 
        meta: { group: 'rank_setting', root: false }
      },
      {
        path: 'admin',
        name: 'admin_list',
        component: () => import('../views/AdminList.vue'), 
        meta: { group: 'admin', root: true }
      },
      {
        path: 'admin/create',
        name: 'admin_create',
        component: () => import('../views/AdminAdd.vue'), 
        meta: { group: 'admin', root: false }
      },
      {
        path: 'admin/:admin_id',
        name: 'admin_modify',
        component: () => import('../views/AdminModify.vue'), 
        meta: { group: 'admin', root: false }
      },
      {
        path: 'user',
        name: 'user_list',
        component: () => import('../views/UserList.vue'), 
        meta: { group: 'user', root: true }
      },
      {
        path: 'user/create',
        name: 'user_create',
        component: () => import('../views/UserAdd.vue'), 
        meta: { group: 'user', root: false }
      },
      {
        path: 'user/:user_id',
        name: 'user_modify',
        component: () => import('../views/UserModify.vue'), 
        meta: { group: 'user', root: false }
      },
      {
        path: 'order_chart',
        name: 'order_chart',
        component: () => import('../views/StatisticView.vue'),
        meta: {group: 'order', root: false }
      },
      {
        path: 'chart/:group',
        name: 'user_chart',
        component: () => import('../views/GroupView.vue'),
        meta: {group: 'user', root: false }
      },
      {
        path: 'news',
        name: 'news_list',
        component: () => import('../views/NewsList.vue'), 
      },
      {
        path: 'point_log',
        name: 'pointlog_list',
        component: () => import('../views/PointlogList.vue'), 
        meta: { group: 'log', root: true }
      },
      {
        path: 'news/create',
        name: 'news_create',
        component: () => import('../views/NewsAdd.vue'), 
      },
      {
        path: 'news/:news_id',
        name: 'news_modify',
        component: () => import('../views/NewsModify.vue'), 
      },
      {
        path: 'currency',
        name: 'currency_modify',
        component: () => import('../views/JpCurrency.vue'), 
        meta: { group: 'currency', root: true }
      },
      {
        path: 'krw',
        name: 'krw_modify',
        component: () => import('../views/KrwCurrency.vue'), 
        meta: { group: 'currency_krw', root: true }
      },
      {
        path: 'cny',
        name: 'cny_modify',
        component: () => import('../views/CnyCurrency.vue'), 
        meta: { group: 'currency_cny', root: true }
      },
      {
        path: 'htc_order',
        name: 'htc_list',
        component: () => import('../views/HtcView.vue'), 
        meta: { group: 'htc', root: true }
      },
      {
        path: 'order',
        name: 'order_list',
        component: () => import('../views/OrderList.vue'), 
        meta: { group: 'order', root: true }
      },
      {
        path: 'order/:order_id',
        name: 'order_modify',
        component: () => import('../views/OrderModify.vue'), 
        meta: { group: 'order', root: false }
      },
      {
        path: 'pay_msg',
        name: 'pay_msg',
        component: () => import('../views/PaymsgList.vue'), 
        meta: { group: 'order', root: false }
      },
      {
        path: 'line',
        name: 'line',
        component: () => import('../views/LineMsg.vue'), 
        meta: { group: 'line', root: true }
      },
      {
        path: 'jp_admin',
        name: 'jp_admin',
        component: () => import('../views/JpAdmin.vue'), 
        meta: { group: 'jp_order', root: true }
      },
      {
        path: 'jp_admin/:order_id',
        name: 'jp_modify',
        component: () => import('../views/JpModify.vue'), 
        meta: { group: 'jp_order', root: false }
      },
      {
        path: 'kr_admin',
        name: 'kr_admin',
        component: () => import('../views/KrAdmin.vue'), 
        meta: { group: 'kr_order', root: true }
      },
      {
        path: 'kr_admin/:order_id',
        name: 'kr_modify',
        component: () => import('../views/KrModify.vue'), 
        meta: { group: 'kr_order', root: false }
      },
      {
        path: 'cn_admin',
        name: 'cn_admin',
        component: () => import('../views/CnAdmin.vue'), 
        meta: { group: 'cn_order', root: true }
      },
      {
        path: 'cn_admin/:order_id',
        name: 'cn_modify',
        component: () => import('../views/CnModify.vue'), 
        meta: { group: 'cn_order', root: false }
      },
      {
        path: 'pkg_image',
        name: 'pkg_image',
        component: () => import('../views/PkgImage.vue'), 
        meta: { group: 'pkg_image', root: true }
      },
      {
        path: 'pkg_image_upload',
        name: 'upload_image',
        component: () => import('../views/UploadImage.vue'), 
        meta: { group: 'pkg_image', root: false }
      },
      {
        path: 'service',
        name: 'admin_service',
        component: () => import('../views/AdminService.vue'), 
        meta: { group: 'admin_service', root: true }
      },
      {
        path: 'service/:service_id',
        name: 'admin_service_modify',
        component: () => import('../views/ServiceModify.vue'), 
        meta: { group: 'admin_service', root: false }
      },
    ]
  },
  {
    path: '/verify/:uid/:token',
    name: 'verify',
    component: () => import('../views/VerifyView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const LOGS = [];
router.afterEach((to, from) => {
  (to.path == '/') && (to.path = '/main');
  if ((to.meta.group != from.meta.group && to.meta.root) || (to.meta.group == from.meta.group && to.meta.root && from.meta.root)) {
    from.meta.transition = 'none';
    to.meta.transition = 'none';
    LOGS.length = 0;
    LOGS.push(to.path);
  } else {
    let pos = LOGS.indexOf(to.path);
    if (!to.meta.root && pos < 0) {
      LOGS.push(to.path);
      from.meta.transition = 'left';
      to.meta.transition = 'left';
    } else {
      LOGS.splice(pos + 1);
      from.meta.transition = 'right';
      to.meta.transition = 'right';
    }
  }
  to.meta.back = (LOGS.length > 1) ? LOGS[LOGS.length - 2] : null;
});

export default router
